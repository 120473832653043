import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

type Params = {
  dateOfBirth: string;
};
type Data = undefined;

const NAME = '/Account/ChangeDateOfBirth';
const ContactsSetDateOfBirth = createModernEndpoint<Params, Data>(NAME);

export default ContactsSetDateOfBirth;
export { ContactsSetDateOfBirth };
