import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import EditBirthday from 'domains/profile/containers/edit-birthday';

const TITLE = 'Update Date of Birth';

const ProfileUpdateBirthdayPage: ApplicationPage = () => {
  return (
    <Page {...DEFAULT_AUTHORIZED_PAGE_PROPS}>
      <EditBirthday />
    </Page>
  );
};

ProfileUpdateBirthdayPage.getMeta = () => ({ title: TITLE });

ProfileUpdateBirthdayPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileUpdateBirthdayPage;
