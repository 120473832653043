import { MIN_DATE_OF_BIRTH, TODAY, BIRTHDAY_SCHEMA } from '@swe/shared/tools/validation/constants';
import Form, { yup } from '@swe/shared/ui-kit/components/form';

import { formatDate } from '@swe/shared/utils/date';

import { useCallback } from 'react';

import PageHeader from 'common/components/page-header';
import { useStoreConfig } from 'common/providers/config';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import ContactsSetDateOfBirth from 'endpoints/profile/contacts/set-birthday';

type EditBirthdayValues = {
  dateOfBirth: DateISOString;
};

const VALIDATION_SCHEMA = yup.object({
  dateOfBirth: BIRTHDAY_SCHEMA(),
});

const EditBirthday = () => {
  const { minimalCustomerAge } = useStoreConfig();
  const navigate = useRouterNavigate();
  const { revalidate, user } = useCurrentUser();
  const submitHandler = useCallback(
    async ({ dateOfBirth }: EditBirthdayValues) => {
      await ContactsSetDateOfBirth.request({ dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd') });
      await revalidate();
      await navigate(-1);
    },
    [navigate, revalidate],
  );

  return (
    <div>
      <PageHeader
        title="Date of Birth"
        defaultBackRoute={Routes.ProfileContacts}
        enableMarginBottom
      />
      <Form.Builder<EditBirthdayValues>
        initialValues={{ dateOfBirth: user?.dateOfBirth ?? '' }}
        validationSchema={VALIDATION_SCHEMA}
        validationContext={{ minimalCustomerAge }}
        onSubmit={submitHandler}
        name="edit-birthday"
      >
        <Form.InputDate
          readonly={!!user?.isDateOfBirthFilledFromDocuments}
          name="dateOfBirth"
          label="Date of birth"
          placeholder="Enter date of birth"
          max={TODAY}
          min={MIN_DATE_OF_BIRTH}
          initialScreen="years"
        />
        <Form.SubmitButton block>Save</Form.SubmitButton>
      </Form.Builder>
    </div>
  );
};

export default EditBirthday;
